import Dom = require("Everlaw/Dom");
import Input = require("Everlaw/Input");
import Is = require("Everlaw/Core/Is");
import Tooltip = require("Everlaw/UI/Tooltip");
import Util = require("Everlaw/Util");
import dijit__HasDropDown = require("dijit/_HasDropDown");
import dijit__WidgetBase = require("dijit/_WidgetBase");
import DijitTooltipDialog = require("dijit/TooltipDialog");
import dijit_popup = require("dijit/popup");
import declare = require("dojo/_base/declare");
import dojo_on = require("dojo/on");
import eventUtil = require("dojo/_base/event");
import { FocusDiv, makeFocusable } from "Everlaw/UI/FocusDiv";
import { EverColor } from "design-system";

const DropDownLabeledIcon = declare([dijit__WidgetBase, dijit__HasDropDown], {
    // We set maxHeight to 0 so that dijit does not set the height of the dialog wrapper dynamically
    // (based on the height of the view port and the height of the '_aroundNode') each time it opens.
    // This was causing a vertical scrollbar to appear on the dijitTooltipDialogPopup (the outermost
    // div of the dialog of this DropDownLabeledIcon).
    maxHeight: 0,
    orient() {
        return this.dropDownPosition;
    },
    loadAndOpenDropDown: function loadAndOpenDropDown() {
        if (Dom.hasAttr(this.domNode, "disabled")) {
            return;
        }
        this.setDropDownPosition();
        this.inherited({ callee: loadAndOpenDropDown }, arguments);
    },
    repositionDropDown() {
        if (this._opened) {
            const oldStack = dijit_popup._stack;
            // Clear the popup stack temporarily so that this doesn't get closed and reopened.
            dijit_popup._stack = [];
            this.openDropDown();
            // Restore popup stack
            dijit_popup._stack = oldStack;
        }
    },
    setDropDownPosition: function setDropdownPosition() {
        this.dropDownPosition = this.orient();
    },
    destroy: function destroy(...args: unknown[]) {
        this.domNode = null;
        this.srcNodeRef = null;
        this.inherited({ callee: destroy }, args);
    },
});

/**
 * A generic tooltip dialog - a tooltip-like panel that can be opened around some opener node.
 * If you want to put a menu in, see UI.PopoverMenu.
 */
class Popover {
    dialogContent: Dom.Nodeable;
    orient: string[] | (() => string[]);
    tooltip: Dom.Content;
    disabledTooltip: Dom.Content;
    hideOnMouseLeave: boolean;
    onClose(fromButton: boolean) {}
    onOpen() {}
    preOpen() {}
    onDialogBlur() {}
    // internal
    button: any; // currently used externally only as a hack (Highlighting.ts and GridColumns.ts)
    private container: HTMLElement;
    protected _isOpen: boolean;
    /**
     * Prevent the widget from focusing on its icon/node. Useful for when we need to manually
     * handle focus.
     */
    protected disableFocus = false;
    protected dialog: any;
    connect: dojo_on.Handle[];
    protected tt: Tooltip;
    protected _focusDiv: FocusDiv | null;
    private toDestroy: Util.Destroyable[] = [];
    protected scrollDiv: HTMLElement | null;
    constructor(
        params: Popover.Params,
        protected node: HTMLElement,
    ) {
        Object.assign(this, params);
        if (!Dom.hasAttr(node, "tabindex")) {
            Dom.setAttr(node, "tabindex", "-1");
        }
        this.container = Dom.div();
        if (Is.defined(params.backgroundColor)) {
            Dom.style(this.container, { backgroundColor: params.backgroundColor });
        }
        if (this.dialogContent) {
            Dom.place(this.dialogContent, this.container);
        }
        const style: Record<string, string> = {};
        if (params.maxWidth) {
            style["maxWidth"] = params.maxWidth;
        }
        this.dialog = new DijitTooltipDialog({
            content: this.container,
            class: "menuDropdown" + (params.class ? " " + params.class : ""),
            closable: true,
            focus: () => {
                this.focus();
            },
            onShow: () => {
                const wasOpen = this._isOpen;
                if (!wasOpen) {
                    this.preOpen();
                }
                this._onShow();
                this.updateScrolling();
                if (Is.defined(params.zIndex)) {
                    Dom.style(this.dialog.domNode.parentNode, { zIndex: params.zIndex });
                }
                if (!wasOpen) {
                    this.onOpen();
                }
            },
            onHide: () => {
                this._isOpen = false;
                if (this.tt) {
                    this.tt.disabled = false;
                }
                this.onClose(false);
            },
            onBlur: () => {
                this.onDialogBlur();
            },
            onMouseLeave: this.hideOnMouseLeave ? this.close.bind(this, false) : () => {},
            style: style,
        });
        this.connect = params.propagateClickEvt
            ? []
            : [
                  dojo_on(this.node, Input.press, (e) => {
                      eventUtil.stop(e);
                  }),
              ];
        Dom.addClass(this.node, "action");
        this.button = new DropDownLabeledIcon(
            {
                orient: () => {
                    const o = <any>this.orient;
                    if (Is.func(o)) {
                        return o();
                    }
                    return o || ["before-centered", "after-centered"];
                },
                _aroundNode: params.aroundNode,
                dropDown: this.dialog,
                _stopClickEvents: false,
                focus: () => {
                    if (!this.disableFocus) {
                        this.node.focus();
                    }
                },
            },
            this.node,
        );
        // The orientation priority doesn't get set by dojo until it handles an onClick event.
        // (See DropDownLabeledIcon#loadAndOpenDropDown above.) So, we need to set it explicitly
        // here in case the dialog is shown programmatically via open(), otherwise the
        // orientation priority won't be honored.
        this.button.setDropDownPosition();
        if (this.tooltip) {
            this.tt = new Tooltip(this.node, this.tooltip);
        }
        if (params.makeFocusable) {
            this._focusDiv =
                params.customFocusDiv
                || makeFocusable(
                    this.node,
                    params.focusStyling || "focus-with-space-style",
                    params.focusDivPos,
                );
            this.toDestroy.push(
                this._focusDiv,
                Input.fireCallbackOnKey(this.container, [Input.ESCAPE], (e) => {
                    this.toggle();
                    this._focusDiv?.focus();
                    eventUtil.stop(e);
                }),
                Input.fireCallbackOnKey(this._focusDiv.node, [Input.ENTER], (e) => {
                    eventUtil.stop(e);
                    this.toggle();
                    this.focusPopup();
                }),
            );
        }
    }
    protected _onShow(): void {
        if (this.tt) {
            this.tt.close();
            this.tt.disabled = true;
        }
        this._isOpen = true;
    }
    getNode() {
        return this.node;
    }
    updateScrolling() {
        const parent = this.dialog.contentsNode.parentNode;
        Dom.toggleClass(
            this.dialog.contentsNode,
            "scrolling",
            parent.scrollHeight > parent.clientHeight,
        );
        if (parent.scrollHeight > parent.clientHeight) {
            // In order to programmatically adjust the scroll height in the TTD, we need access to
            // the scrollable div. The easiest way to get there is by referencing the 3rd parent
            // element of this.container. If we're going to need to enable scrolling, we store a
            // reference to the scrollable div here.
            this.scrollDiv = this.container.parentElement?.parentElement?.parentElement || null;
        }
    }
    focus() {
        if (this.disableFocus) {
            return;
        }
        // Default implementation of focus - focuses the popover.
        if (this.dialog.domNode && this.isOpen()) {
            this.dialog.domNode.focus();
        } else {
            this.button.focus();
        }
    }
    focusIcon() {
        if (this.disableFocus) {
            return;
        }
        this.button.focus();
    }
    focusPopup() {
        if (this.disableFocus) {
            return;
        }
        this.dialog.domNode && this.dialog.domNode.focus();
    }
    open() {
        this.preOpen();
        this.button.openDropDown();
        this.onOpen();
    }
    loadAndOpen() {
        this.preOpen();
        this.button.loadAndOpenDropDown();
        this.onOpen();
    }
    close(fromButton = false) {
        this.button.closeDropDown();
        this.onClose(fromButton);
    }
    toggle() {
        if (this._isOpen) {
            this.close();
        } else {
            this.open();
        }
    }
    isOpen() {
        return this._isOpen;
    }
    destroy(preserveNode = false) {
        Util.destroy(this.connect);
        this.connect = [];
        if (this.tt) {
            this.tt.destroy();
        }
        this.button.destroy(preserveNode);
        this.dialog.destroy();
        Util.destroy(this.toDestroy);
        this._focusDiv = null;
    }
    setContent(newContent: Dom.Nodeable) {
        this.dialogContent = newContent;
        Dom.place(newContent, this.container, "only");
    }
    setDisabled(state: boolean) {
        this.button.set("disabled", state);
        if (this.disabledTooltip && this.tooltip) {
            this.tt.setContent(state ? this.disabledTooltip : this.tooltip);
        }
    }
    /**
     * Toggle the widget's ability to focus on its icon/node. Useful for when we need to manually
     * handle focus.
     */
    setDisableFocus(state: boolean): void {
        this.disableFocus = state;
    }
    reposition() {
        this.button.repositionDropDown();
    }
    getDijitTooltipContainer(): HTMLElement {
        return this.dialog.domNode.firstElementChild;
    }
}

module Popover {
    export interface Params {
        dialogContent?: Dom.Nodeable;
        orient?: string[] | (() => string[]);
        /**
         * The tooltip text to show on the provided node. Use this instead of adding the tooltip
         * yourself, and the dropdown will take care of cleaning it up and hiding it when appropriate.
         */
        tooltip?: Dom.Content;
        disabledTooltip?: Dom.Content;
        hideOnMouseLeave?: boolean;
        onOpen?: () => void;
        preOpen?: () => void;
        onClose?: (fromButton?: boolean) => void;
        focus?: () => void;
        onDialogBlur?: () => void;
        aroundNode?: HTMLElement; // node to position the dialog on, if different from that for tooltip
        class?: string;
        maxWidth?: string;
        // If true, the Popover will be made focusable
        makeFocusable?: boolean;
        // When makeFocusable is true and customFocusDiv is provided, then customFocusDiv is used
        // instead of creating a new one. Use this option when the trigger node already has its own
        // focusDiv and a new one should not be made.
        customFocusDiv?: FocusDiv;
        // If makeFocusable is true, it will use this styling. The default styling is
        // "focus-with-space-style".
        focusStyling?: string | string[];
        // If makeFocusable is true, the FocusDiv will use this positioning.
        focusDivPos?: string;
        zIndex?: string;
        propagateClickEvt?: boolean;
        backgroundColor?: EverColor;
    }

    /**
     * Utility method to explicitly blur if any children of container are currently focused. Some widgets
     * (namely tinymce) require this explicit blur to submit. This method should be called in a
     * Popover#onClose handler.
     */
    export function blurOnClose(container: Dom.Nodeable) {
        const active = document.activeElement;
        if (
            active
            && active.tagName.toLowerCase() === "iframe"
            && Dom.node(container).contains(active)
        ) {
            // If somehow the panel gets closed while an editor is still focused, make sure
            // that it gets blurred.
            if (active instanceof HTMLElement) {
                active.blur();
            }
        }
    }

    /**
     * Utility method to handle unexpected blurs in a tinymce editor (ex pasting in IE11). This method
     * should be call in a Popover#onDialogBlur handler.
     */
    export function trapDialogBlur(dialog: Popover, container: Dom.Nodeable) {
        if (
            document.activeElement
            && document.activeElement.tagName.toLowerCase() === "iframe"
            && Dom.node(container).contains(document.activeElement)
        ) {
            // We had an editor open when we blurred. We want to blur the editor itself but keep
            // the dialog panel open.
            // This hack prevents the dialog from getting closed as the blur gets processed up the
            // widget stack. This avoids an annoying flash of the panel.
            dialog.button._opened = false;
            setTimeout(() => {
                // After this blur has been processed, we focus the icon (which ensures that the
                // tinymce editor gets blurred) and make sure the panel is open (which resets the
                // _opened state we changed earlier).
                dialog.focusPopup();
                dialog.open();
            }, 0);
        }
    }

    /**
     * A variant of the above that doesn't require that the current active element be an iframe or
     * check if it is a child of a provided container, and does not focus the dialog unless focusDialog
     * is also optionally set to true. This method allows for a widget which opens some other popup
     * (e.g. Datebox) to be usable from within a Popover--otherwise, focusing on that widget's
     * popup will onfocus the dialog and thus close the other popup.
     * @param dialog
     * @param time
     * @param focusDialog
     * @param additional - additional actions to be taken after trapping the dialog blur
     */

    export function trapDialogBlurForNonIframes(
        dialog: PopoverWithTrappedBlur,
        time = 0,
        focusDialog = false,
        additional?: () => void,
    ): void {
        dialog.button._opened = false;
        setTimeout(() => {
            focusDialog && dialog.focusPopup();
            dialog.open(true);
            additional && additional();
        }, time);
    }

    // Slight extension of the Popover class for the above so that whether or not the dialog
    // was reopened due to a trapped blur can be communicated to the onOpen function
    interface PopoverWithTrappedBlurParams extends Popover.Params {
        onOpen: (fromTrappedBlur?: boolean) => void;
    }
    export class PopoverWithTrappedBlur extends Popover {
        constructor(
            params: PopoverWithTrappedBlurParams,
            protected override node: HTMLElement,
        ) {
            super(params, node);
        }
        override onOpen(fromTrappedBlur = false): void {}
        override open(fromTrappedBlur = false): void {
            // button.openDropDown normally calls onOpen() through onShow(), which is in turn called
            // through dojo. To get around this, briefly get rid of the onOpen line from dialog.onShow
            // while calling openDropDown here, and then re-add it immediately afterwards.
            const oldDialogOnShow = this.dialog.onShow;
            this.dialog.onShow = () => this._onShow();
            this.button.openDropDown();
            this.dialog.onShow = oldDialogOnShow;
            this.onOpen(fromTrappedBlur);
        }
    }
}

export = Popover;
